import { currencyString, currencyCb } from '../../components/common/Currency'

class AdditionalPart {
  partId: number
  partNumber: string
  name: string
  totalPrice: number
  unitPrice: number
  quantity: number
  thumbnailImagePath: string
  currencySymbol: string
  currencyCode: string
  category: string
  unit_of_measurement?: string
  unit_of_measurement_price_label?: string

  constructor(
    partId: number,
    partNumber: string,
    name: string,
    unitPrice: string,
    currencySymbol: string,
    currencyCode: string,
    category: string,
    thumbnail_image_path: string,
    quantity?: number,
    unit_of_measurement?: string,
    unit_of_measurement_price_label?: string
  ) {
    this.partId = partId
    this.partNumber = partNumber
    this.name = name
    this.totalPrice = quantity ? currencyCb(unitPrice).multiply(quantity).value : 0
    this.unitPrice = parseFloat(unitPrice)
    this.quantity = quantity || 0
    this.thumbnailImagePath = thumbnail_image_path
    this.currencySymbol = currencySymbol
    this.currencyCode = currencyCode
    this.category = category
    this.unit_of_measurement = unit_of_measurement
    this.unit_of_measurement_price_label = unit_of_measurement_price_label
  }

  setQuantity(quantity): void {
    this.quantity = parseInt(quantity)
    this.totalPrice = currencyCb(this.unitPrice).multiply(quantity).value
  }

  clearQuantity(): void {
    this.quantity = 0
    this.totalPrice = 0
  }

  getThumbnailImagePath(): string {
    return this.thumbnailImagePath
  }

  getPrice(): number {
    return this.totalPrice
  }

  displayUnitPrice(): string {
    return currencyString(
      currencyCb(this.unitPrice),
      this.currencySymbol,
      this.currencyCode,
      this.unit_of_measurement_price_label
    )
  }

  displayTotalPrice(): string {
    return currencyString(
      currencyCb(this.totalPrice ? this.totalPrice : 0),
      this.currencySymbol,
      this.currencyCode
    )
  }
}

export default AdditionalPart
