import React from 'react'
import DesignCardWrapper from './DesignCardWrapper'
import Card from '../common/cards/Card'

const SimplifiedDesignCardClass = props => {
  const {
    id,
    name,
    image_thumb_path,
    contain_motion_code,
    is_request = false,
    showOverlay = true,
    url,
    onSelect,
  } = props

  // URL to view the design
  const viewDesignUrl = `/designs/${url ? `${url}-${id}` : id}`

  // URL to view the design request
  const viewRequestUrl = `/requests/${id}`

  return (
    <Card
      url={is_request ? viewRequestUrl : viewDesignUrl}
      innerClassName={'design-card-img'}
      imgName={name}
      imgSrc={image_thumb_path}
      extraInfoAboveBody={
        <>
          {contain_motion_code && <div className='design-card-programmed-banner'>Programmed</div>}
        </>
      }
      showOverlay={showOverlay}
      simplifiedOverlayInfo={
        <>
          <button className='button button-block button-white-outline' onClick={onSelect}>
            Select
          </button>
        </>
      }
      name={<div className='design-card-name'>Template for {name}</div>}
    />
  )
}

const SimplifiedDesignCard = DesignCardWrapper(SimplifiedDesignCardClass)
export const SimplifiedDesignCardStatic = SimplifiedDesignCardClass // Stateless non-functional design card
// The wrapped card which performs state fetches must be the default
// This is because ReactRailsUJS cannot access non-default component constructors
// And that component constructor is passed into the assembler through an ERB template using such a constructor
export default SimplifiedDesignCard
