import React from 'react'
import { capitalizeFirstLetter } from '../../helpers/StringHelper'

class AddCollaboratorField extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div className={this.props.status == 'adding' ? 'row slideInDown animated' : 'row'}>
        <div className='col-sm-6'>
          <div
            className='collaborator-field form-group flex-centering'
            style={{ justifyContent: 'flex-start' }}
          >
            {this.props.status !== 'adding' ? (
              <div
                className='collaborator-field flex-centering'
                style={{ justifyContent: 'flex-start' }}
              >
                <img
                  src={
                    this.props.avatar_url
                      ? this.props.avatar_url
                      : 'https://assets.vention.io/png/avatars/default_avatar.png'
                  }
                  className='collaborator-avatar'
                  alt='user avatar'
                />
                <span className='collaborator-email'>{this.props.name_or_email}</span>
              </div>
            ) : (
              <input
                className='form-control'
                name='email'
                data-status={this.props.status}
                value={this.props.email}
                data-id={this.props.id}
                onChange={this.props.updateDesign.bind(this)}
                placeholder='Type email'
                autoComplete='new-password'
              />
            )}
          </div>
        </div>
        <div className='col-sm-3'>
          <div className='collaborator-field form-group'>
            {this.props.status === 'pending' ? (
              this.props.access === 'v' ? (
                <div className='form-control'>Design Viewer</div>
              ) : (
                <div className='form-control'>Design Collaborator</div>
              )
            ) : this.props.access === 'lead' ? (
              <div className='form-control'>Design Lead</div>
            ) : (
              <div className='select-wrapper'>
                <select
                  name='access'
                  className='form-control'
                  value={this.props.access}
                  data-status={this.props.status}
                  data-id={this.props.id}
                  onChange={this.props.updateDesign.bind(this)}
                >
                  <option value='v'>Design Viewer</option>
                  <option value='c'>Design Collaborator</option>
                  {this.props.leadDesigner ? <option value='lead'>Design Lead</option> : null}
                </select>
              </div>
            )}
          </div>
        </div>
        <div className='col-sm-2'>
          <div className='collaborator-field form-group flex-centering'>
            {capitalizeFirstLetter(this.props.status)}
          </div>
        </div>
        <div className='col-sm-1'>
          {this.props.access === 'lead' ||
          this.props.currentUser?.id === this.props?.originalLeadDesigner?.id && this.props.name_or_email === this.props.currentUser?.email ? null : (
            <div className='collaborator-field form-group flex-centering'>
              <i
                className='fal fa-trash-alt fa-lg'
                aria-hidden='true'
                style={{ cursor: 'pointer' }}
                onClick={this.props.removeCollaboratorButton.bind(
                  this,
                  this.props.status,
                  this.props.id
                )}
              ></i>
            </div>
          )}
        </div>
      </div>
    )
  }
}
export default AddCollaboratorField
