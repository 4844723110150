import httpClient from '../httpClient'
import { FetchDesignCategoriesResponse, FetchProductizedApplicationsResponse } from './Types'

const DesignCategoryService = {
  fetchDesignCategories: async function fetchDesignCategories() {
    try {
      const url = '/public_api/design_categories'
      const response = await httpClient.get<FetchDesignCategoriesResponse>(url)
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },

  fetchProductizedApplications: async function fetchProductizedApplications() {
    try {
      const url = '/public_api/design_categories/productized_applications_by_use_case'
      const response = await httpClient.get<FetchProductizedApplicationsResponse>(url)
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  },
}

export default DesignCategoryService
