import React from 'react'

interface Props {
  readonly quantity: number
  readonly changeQuantity: (quantity: number) => void
}
const PartQuantitySelector: React.FC<Props> = ({ quantity, changeQuantity }) => {
  const decreaseQuantity: () => void = () => {
    if (quantity > 0) {
      changeQuantity(quantity - 1)
    }
  }

  const updateQuantity: (quantity: number) => void = quantity => {
    changeQuantity(quantity > 999 ? Math.floor(quantity / 10) : quantity)
  }

  const increaseQuantity: () => void = () => {
    updateQuantity(quantity > 998 ? quantity : quantity + 1)
  }

  return (
    <div className='part-quantity-selector'>
      <button
        onClick={decreaseQuantity}
        className='part-quantity-selector-btn part-quantity-selector-btn-minus'
      >
        <i className='fas fa-minus'></i>
      </button>
      <input
        type='number'
        id='part-quantity-selector-input'
        min='0'
        value={quantity.toString()}
        onBlur={e => {
          updateQuantity(parseInt(e.target.value))
        }}
        onChange={e => {
          updateQuantity(parseInt(e.target.value))
        }}
        onPaste={e => {
          e.preventDefault()
        }}
        onKeyPress={e => {
          if (e.type === 'keypress' && e.charCode === 13) {
            e.currentTarget.blur()
            e.preventDefault()
          }
        }}
      />
      <button
        onClick={increaseQuantity}
        className='part-quantity-selector-btn part-quantity-selector-btn-plus'
      >
        <i className='fas fa-plus'></i>
      </button>
    </div>
  )
}

export default PartQuantitySelector
