import React, { useState } from 'react'
import AdditionalPart from '../../../../models/checkout/AdditionalPart'
import CartMinimalPartRow from '../../../checkout/CartMinimalPartRow'
import { CheckoutHasMissingMachineMotionSafetyEquipment } from '../../../../api/Checkout/checkout-cart-contents'

interface Props {
  readonly category: string
  readonly additionalParts: ReadonlyArray<AdditionalPart>
  readonly recomputePrice: () => void
  readonly hasMissingMachineMotionSafetyEquipment?: CheckoutHasMissingMachineMotionSafetyEquipment
  readonly resetPartQuantityKey?: number
}

const AdditionalPartsAccordion: React.FC<Props> = ({
  category,
  additionalParts,
  recomputePrice,
  hasMissingMachineMotionSafetyEquipment,
  resetPartQuantityKey,
}) => {
  const [open, setOpen] = useState(true)
  const displayAdditionalParts: () => React.ReactNode = () => {
    return additionalParts.map((additionalPart, index) => {
      return (
        <CartMinimalPartRow
          key={`${index}-${resetPartQuantityKey}`}
          additionalPart={additionalPart}
          recomputePrice={recomputePrice}
        />
      )
    })
  }
  return (
    <div className={`additional-parts-accordion${open ? ' open' : ''}`}>
      {category === 'Safety Equipment' && (
        <div className='form-error-message'>
          {`Your cart contains MachineMotion 2. You will require 
            ${hasMissingMachineMotionSafetyEquipment?.count_of_missing_emergency_parts}
          E-Stop module(s) to operate safely.`}
        </div>
      )}
      <button
        className='additional-parts-accordion-btn'
        onClick={() => {
          setOpen(!open)
        }}
      >
        <span className='additional-parts-accordion-btn-title'>{category}</span>
        <span className='additional-parts-accordion-btn-chevron'></span>
      </button>
      <div className='additional-parts-accordion-content'>{displayAdditionalParts()}</div>
    </div>
  )
}

export default AdditionalPartsAccordion
