// This should be changed to act as a wrapper around DesignCategoriesSelect which will replace Select (if compatible)
import React from 'react'
import Select from 'react-select'
import DesignCategoryService from '../../api/DesignCategories/Service'
import Loading from './Loading'

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
  </div>
)

class VentionSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSelection: null,
      categorySelectList: null,
    }
    this.updateInput = this.updateInput.bind(this)
    this.category = React.createRef()
  }

  componentDidMount() {
    this.fetchDesignCategories()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.design_category &&
      prevProps.design_category &&
      prevProps.design_category.id !== this.props.design_category.id
    ) {
      this.buildSelectList(this.state.designCategories)
    }
  }

  fetchDesignCategories() {
    DesignCategoryService.fetchDesignCategories().then(response => {
      this.setState({ designCategories: response.design_categories })
      this.buildSelectList(response.design_categories)
    })
  }

  updateInput(selectedOption) {
    this.setState({ currentSelection: selectedOption })
    this.props.updateDesign({
      target: 'category',
      payload: {
        design_category: selectedOption,
      },
    })
  }

  buildSelectList(designCategories) {
    const categorySelectList = designCategories.map((category, index) => {
      const options = category.subcategories.map((subCategory, subIndex) => {
        let option = {
          value: subCategory.id,
          categoryCode: category.code,
          label: subCategory.name,
          ...subCategory,
        }
        // If we find the designs existing subcategory, set it as the currently selected subcategory
        // else if no existing category (new design) default to first category
        if (this.props.design_category && subCategory.id === this.props.design_category.id) {
          this.setState({ currentSelection: option })
        } else if (
          !this.props.design_category &&
          !this.state.currentSelection &&
          designCategories.length - 1 == index &&
          category.subcategories.length - 1 == subIndex
        ) {
          this.updateInput(option)
        }
        return option
      })

      return {
        value: category.id,
        label: category.name,
        options: options,
        ...category,
      }
    })

    this.setState({ categorySelectList: categorySelectList })
  }

  render() {
    const { currentSelection, categorySelectList } = this.state
    return (
      <div className='vention-select-categories'>
        {categorySelectList ? (
          <Select
            className='basic-single select-wrapper'
            classNamePrefix='select'
            value={currentSelection}
            isDisabled={false}
            isLoading={false}
            isSearchable={true}
            name='color'
            options={categorySelectList}
            formatGroupLabel={formatGroupLabel}
            onChange={this.updateInput}
            ref={this.category}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
              indicatorsContainer: () => null,
            }}
          />
        ) : (
          <Loading />
        )}
      </div>
    )
  }
}

export default VentionSelect
