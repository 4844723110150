import React from 'react'
import { getPartVersionAndNumberString } from '../../helpers/Parts'
import AdditionalPart from '../../models/checkout/AdditionalPart'
import PartQuantitySelector from './PartQuantitySelector'
import ReactTooltip from 'react-tooltip'

interface Props {
  readonly additionalPart: AdditionalPart
  readonly recomputePrice: () => void
}
interface State {
  readonly quantity: number
}

class CartMinimalPartRow extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.changeQuantity = this.changeQuantity.bind(this)
    this.state = { quantity: this.props.additionalPart.quantity }
  }
  changeQuantity(quantity: number): void {
    this.setState({ quantity: quantity }, () => {
      this.props.additionalPart.setQuantity(quantity)
      this.props.recomputePrice()
      this.forceUpdate()
    })
  }
  render(): React.ReactNode {
    return (
      <div className='cart-row-wrapper cart-minimal-part-row'>
        <div className='cart-item-overview' key={this.props.additionalPart.partId}>
          <div className='cart-item-details'>
            <div className='cart-item-img'>
              <img
                src={this.props.additionalPart.getThumbnailImagePath()}
                alt={this.props.additionalPart.name}
              />
            </div>
            <div className='cart-item-description'>
              <div className='cart-part-link'>
                <ReactTooltip
                  id={`tooltip-${this.props.additionalPart.partId}`}
                  place='top'
                  effect='solid'
                >
                  {this.props.additionalPart.name}
                </ReactTooltip>
                <a
                  className='additional-part-name'
                  target='_blank'
                  rel='noopener noreferrer'
                  href={`/parts/${this.props.additionalPart.partId}`}
                  data-tip
                  data-for={`tooltip-${this.props.additionalPart.partId}`}
                >
                  {this.props.additionalPart.name}
                </a>
              </div>
              <div className='cart-part-number'>
                {getPartVersionAndNumberString(this.props.additionalPart.partNumber)}
              </div>
            </div>
          </div>
          <div className='cart-item-unit-price'>{this.props.additionalPart.displayUnitPrice()}</div>
          <div className='cart-item-quantity'>
            <PartQuantitySelector
              quantity={this.state.quantity}
              changeQuantity={this.changeQuantity}
            />
          </div>
          <div className='cart-item-subtotal'>{this.props.additionalPart.displayTotalPrice()}</div>
        </div>
      </div>
    )
  }
}
export default CartMinimalPartRow
