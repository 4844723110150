import React, { useState } from 'react'
import Modal from 'react-modal'
import AdditionalPartsAccordion from './AddPartsAccordion'
import AdditionalPart from '../../../../models/checkout/AdditionalPart'
import Currency, { currencyCb } from '../../Currency'
import { CheckoutHasMissingMachineMotionSafetyEquipment } from '../../../../api/Checkout/checkout-cart-contents'
import I18n from '../../../../helpers/I18n'

export interface Props {
  readonly additionalParts: ReadonlyArray<AdditionalPart>
  readonly currencySymbol: string
  readonly currencyCode: string
  readonly modalIsOpen?: boolean
  readonly closeModal?: () => void
  readonly confirmAddParts: (close?: VoidFunction) => void
  readonly recomputePrice: () => void
  readonly additionalPartTotals: number
  readonly addToCartLabel?: string
  readonly skipLabel?: string
  readonly hasMissingMachineMotionSafetyEquipment?: CheckoutHasMissingMachineMotionSafetyEquipment
  readonly disabled?: boolean
}

const AddPartsModal: React.FC<Props> = ({
  additionalParts,
  currencySymbol,
  currencyCode,
  modalIsOpen,
  closeModal,
  confirmAddParts,
  recomputePrice,
  additionalPartTotals,
  addToCartLabel,
  skipLabel = 'Skip',
  hasMissingMachineMotionSafetyEquipment,
  disabled,
}) => {
  const [resetPartQuantityKey, setResetPartQuantityKey] = useState(0)

  const additionalPartsByCategory = (
    additionalParts: ReadonlyArray<AdditionalPart>
  ): { [category: string]: ReadonlyArray<AdditionalPart> } => {
    const categorizedParts = additionalParts.reduce((categories, part) => {
      if (part.category === null) {
        return categories
      }
      categories[part.category] = categories[part.category] || []
      categories[part.category].push(part)
      return categories
    }, {})
    return categorizedParts
  }

  const cartMinimalView = (): React.ReactNode => {
    const categorizedParts = additionalPartsByCategory(additionalParts)
    return Object.entries(categorizedParts).map(([category, additionalParts], index) => (
      <AdditionalPartsAccordion
        key={index}
        category={category}
        additionalParts={additionalParts}
        recomputePrice={recomputePrice}
        hasMissingMachineMotionSafetyEquipment={hasMissingMachineMotionSafetyEquipment}
        resetPartQuantityKey={resetPartQuantityKey}
      />
    ))
  }

  const clearAdditionalParts = () => {
    additionalParts.forEach(additionalPart => {
      additionalPart.setQuantity(0)
      recomputePrice()
    })

    setResetPartQuantityKey(resetPartQuantityKey + 1)
  }

  const count_of_missing_emergency_parts =
    hasMissingMachineMotionSafetyEquipment?.count_of_missing_emergency_parts || 0

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className='react-modal'
      overlayClassName='react-modal-overlay'
      contentLabel='Add Parts Modal'
      ariaHideApp={false}
    >
      <div className='modal-content modal-content-vertical-scroll add-parts-modal'>
        <div className='modal-header'>
          <button type='button' className='close' onClick={closeModal} aria-label='Close'>
            <span aria-hidden='true' className='icon-close'></span>
          </button>
          <h3 className='modal-title text-center bg-letter-spacing'>
            <strong>{ I18n.t('views.checkout.carts.add_ons.need_any') }</strong>
          </h3>
          <button className='modal-clear-button' onClick={clearAdditionalParts}>
            { I18n.t('views.checkout.carts.add_ons.clear_all') }
          </button>
          {!!count_of_missing_emergency_parts && (
            <div className='form-error-message'>
              <span>{'Your cart contains MachineMotion 2.'}</span>
              {`You will require ${count_of_missing_emergency_parts} Emergency Stop Module(s) to operate safely.`}
            </div>
          )}
          <div className='add-parts-modal-table-header'>
            <div>{ I18n.t('views.checkout.carts.add_ons.item') }</div>
            <div>{ I18n.t('views.checkout.carts.add_ons.unit_price') }</div>
            <div>{ I18n.t('views.checkout.carts.add_ons.quantity') }</div>
            <div>{ I18n.t('views.checkout.carts.add_ons.total') }</div>
          </div>
        </div>
        <div className='modal-body'>
          <div className='container-fluid'>{cartMinimalView()}</div>
          <div className='cart-total'>
            <div className='cart-total-label'>Total</div>
            <div className='cart-total-amount'>
              <Currency
                symbol={currencySymbol}
                code={currencyCode}
                currencyCb={currencyCb(additionalPartTotals)}
              />
            </div>
          </div>
        </div>
        <div className='container-fluid text-center'>
          <div className='btn-container'>
            <div className='btn-inner-container'>
              <button
                className='button button-md button-success add-parts-modal-cart-btn'
                onClick={() => confirmAddParts(closeModal)}
                disabled={disabled}
              >
                {addToCartLabel || I18n.t('views.checkout.carts.add_ons.add_to_cart') }
              </button>
              {skipLabel && (
                <button onClick={closeModal} className='button-blank add-parts-modal-skip-btn'>
                  {skipLabel}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default AddPartsModal
