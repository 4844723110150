import React from 'react'
import VentionTag from '../../components/common/VentionTag'
import AddCollaboratorField from '../../components/common/AddCollaboratorField'
import VentionSelect from '../../components/common/VentionSelect'
import Update from 'immutability-helper'
import { validateEmail } from '../../helpers/StringHelper'
import { hasDuplicates } from '../../helpers/Collections'
import SiteInfoStore from '../../stores/SiteInfoStore'
import DesignService from '../../api/Designs/Service'
import DesignCategoryService from '../../api/DesignCategories/Service'
import SimplifiedDesignCard from '../../components/designs/SimplifiedDesignCard'
import Button from '../../components/common/buttons/Button'
import isEqual from 'lodash/isEqual'
import classnames from 'classnames'

class AssemblyCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newAssembly: true,
      saved: false,
      saving: false,
      company_id: null,
      company_domain: null,
      tab: 'n',
      share: false,
      // To keep a copy of existing values
      originalDesign: null,
      // The state of the new/modified design
      design: {
        name: '',
        description: '',
        tags: [],
        design_category: '',
        collaborators: {
          confirmed: [],
          pending: [],
          adding: [{ id: 1, email: '', access: 'c' }],
        },
        salesforce_opportunity_url: null,
        predicted_purchase_quantity: null,
      },
      submitable: true,
      currentUser: null,
      sharable_link: '',
      selected_sharable_link_option: '',
      productizedApplications: [],
      querySettings: {
        pageNumber: 1,
        category: '',
        subCategory: '',
        designAuthor: 'official',
        designsPerPage: '4',
        sortBy: 'last_modified',
        filter_by: 'al',
        is_design_request: false,
        get_tags: true,
        tagList: ['template'],
        tag_match_type: '',
        lifecycle: 'not_archived',
        designer: '',
        filter_by_partner: '',
      },
      errors: {
        nameRequired: false,
      },
    }
    this.callback = undefined
    this.removeCollaboratorButton = this.removeCollaboratorButton.bind(this)
    this.addCollaboratorButton = this.addCollaboratorButton.bind(this)
    this.updateDesign = this.updateDesign.bind(this)
    this.saveDesign = this.saveDesign.bind(this)
    this.setSelf = this.setSelf.bind(this)
    this.setCallback = this.setCallback.bind(this)
    this.setOpenPublishDesignDialog = this.setOpenPublishDesignDialog.bind(this)
    this.openPublishDesignDialog = undefined
    this.setOpenUpdateThumbnailsDialog = this.setOpenUpdateThumbnailsDialog.bind(this)
    this.openUpdateThumbnailsDialog = undefined
    this.toggleShareLink = this.toggleShareLink.bind(this)
    this.sharable_link_input = React.createRef()
    this.cloneDesignTemplate = this.cloneDesignTemplate.bind(this)
    this.copyLink = this.copyLink.bind(this)
    this.showDesignCards = this.showDesignCards.bind(this)
    this.showEditProperties = this.showEditProperties.bind(this)
    this.elements = {
      name: null,
      saveButton: null,
      ventionSelect: null,
      salesforceURL: null,
      salesforcePurchaseQuantity: null,
    }
    this.setNameRef = element => {
      this.elements.name = element
    }
    this.setSaveButtonRef = element => {
      this.elements.saveButton = element
    }
    this.setVentionSelectRef = element => {
      this.elements.ventionSelect = element
    }
    this.setSalesforceURLRef = element => {
      this.elements.salesforceURL = element
    }
    this.setSalesforceQtyRef = element => {
      this.elements.salesforcePurchaseQuantity = element
    }
    this.validURL = url => {
      return url.match(/^(http|https):\/\/.*/)
    }
    this.cantSaveDesign = () => {
      if (this.state.newAssembly) {
        return false
      }
      const invalidQuantity =
        this.state.design.predicted_purchase_quantity == null ||
        this.state.design.predicted_purchase_quantity == ''
      const url = this.state.design.salesforce_opportunity_url
      const invalidURL = url == null || url == '' || this.validURL(url) == null
      return (this.state.ventionEmployee && (invalidQuantity || invalidURL)) || this.state.saving
    }
  }
  componentDidMount() {
    const that = this
    SiteInfoStore.getCurrentUser(currentUser => {
      if (!currentUser) {
        return
      }
      that.setState({
        currentUser: currentUser,
        companyId: currentUser.company_id,
        ventionEmployee: currentUser.vention_employee,
      })
    })
    DesignCategoryService.fetchProductizedApplications().then(response => {
      var productizedApplicationList = []
      response.use_cases.forEach(useCase => {
        if (useCase.productized_applications.length > 0) {
          useCase.productized_applications.forEach(productizedApplication => {
            productizedApplicationList.push({
              code: productizedApplication.code,
              id: productizedApplication.id,
              name: productizedApplication.name,
              image: productizedApplication.productized_application.image_url,
              parent: {
                code: useCase.code,
                id: useCase.id,
                name: useCase.name,
              },
            })
          })
        }
      })
      this.setState({
        productizedApplications: productizedApplicationList,
      })
    })
    $('#edit_assembly_card').on('hidden.bs.modal', function () {
      //Note that setCallback and calling the callback is important for the CAD. The reference to where the callback is used might not be as obvious since the CAD is a seprate APP that is uglified and minified file.
      if (that.state.saved) {
        that.setState({ saved: false })
        if (undefined != that.callback) {
          that.callback(that.state.design)
          that.setCallback(undefined)
        }
      } else {
        if (undefined != that.callback) {
          that.callback(null)
          that.setCallback(undefined)
        }
      }
    })
  }
  setCallback(callback) {
    this.callback = callback
  }
  cloneDesignTemplate(designTemplate) {
    this.setState({
      design: {
        ...this.state.design,
        design_category: designTemplate.design_category,
        id: designTemplate.id,
      },
      tab: 'p',
    })
  }
  setOpenPublishDesignDialog(openPublishDesignDialog) {
    this.openPublishDesignDialog = openPublishDesignDialog
  }
  setOpenUpdateThumbnailsDialog(openUpdateThumbnailsDialog) {
    this.openUpdateThumbnailsDialog = openUpdateThumbnailsDialog
  }
  resetEmptyState() {
    this.setState({
      newAssembly: true,
      tab: 'p',
      share: false,
      originalDesign: null,
      design: {
        name: '',
        description: '',
        tags: [],
        design_category: '',
        collaborators: {
          confirmed: [],
          pending: [],
          adding: [{ id: 1, email: '', access: 'c' }],
        },
        salesforce_opportunity_url: null,
        predicted_purchase_quantity: null,
      },
      submitable: true,
      sharable_link: '',
      selected_sharable_link_option: '',
      errors: {
        nameRequired: false,
      },
    })
  }
  setSelf(designId, clone, tab, companyId, pCurrentUser) {
    const that = this
    const lTab = undefined == tab || tab == '' ? 'n' : tab
    const defaultVisibilityLevel = companyId ? 'c' : 'p'

    DesignService.fetchOneDesign(designId, design => {
      that.setState(
        {
          originalDesign: design,
          design: {
            ...design,
            visibility_level:
              design.visibility_level === 'a' && clone
                ? defaultVisibilityLevel
                : design.visibility_level,
          },
          newAssembly: clone,
          tab: lTab,
          currentUser: pCurrentUser,
          company_id: companyId,
          sharable_link: '',
          selected_sharable_link_option: '',
          share: tab === 'c',
          errors: {
            nameRequired: false,
          },
        },
        function () {
          $('#edit_assembly_card').modal('show')
        }
      )
    })
  }
  componentDidUpdate(_prevProps, prevState) {
    $('.far.fa-info-circle').popover({ container: 'body' })

    const doNotValidateDuplicateEmails = isEqual(
      prevState.design.collaborators,
      this.state.design.collaborators
    )

    if (!doNotValidateDuplicateEmails && this.state.tab === 'p') {
      // if cloning, do not include the previous confirmed/pending collaborators
      let all_emails = this.state.design.collaborators.adding
        .map(function (x) {
          return x.email
        })
        .concat(
          this.isCloning()
            ? []
            : this.state.design.collaborators.confirmed.map(function (x) {
                return x.email
              })
        )
        .concat(
          this.isCloning()
            ? []
            : this.state.design.collaborators.pending.map(function (x) {
                return x.email
              })
        )
      all_emails = all_emails.map(function (x) {
        return x.replace(/^\s+|\s+$/g, '')
      })
      if (hasDuplicates(all_emails)) {
        $('.company_collaborator_duplicate_error_text').removeClass('hidden').addClass('show')
        $(this.elements.saveButton).attr('disabled', true)
      } else {
        $('.company_collaborator_duplicate_error_text').removeClass('show').addClass('hidden')
        $(this.elements.saveButton).attr('disabled', false)
      }
    }
    this.setSaveButtonStatus()
    this.validateSalesforceInputs()
  }

  setSaveButtonStatus() {
    $(this.elements.saveButton).attr('disabled', this.cantSaveDesign())
  }

  validateSalesforceInputs() {
    const url = this.elements.salesforceURL?.value
    $(this.elements.salesforceURL).css('border', url != null && !this.validURL(url) ? '1px solid red' : '')
    $(this.elements.salesforcePurchaseQuantity).css('border', this.elements.salesforcePurchaseQuantity?.value === '' ? '1px solid red' : '')
  }

  updateDesign(e) {
    const design = { ...this.state.design }
    this.validateSalesforceInputs()
    this.setSaveButtonStatus()

    if (e.target === 'category') {
      design.design_category = e.payload.design_category
      return this.setState({ design: design })
    }
    if (e.target == 'tags') {
      design.tags = e.payload
      return this.setState({ design: design })
    }
    if (e.target.name === 'email' || e.target.name === 'access') {
      const index = this.state.design.collaborators[e.target.dataset.status].findIndex(
        field => field.id == e.target.dataset.id
      )
      let design = Update(this.state.design, {
        collaborators: {
          [e.target.dataset.status]: {
            [index]: { [e.target.name]: { $set: e.target.value } },
          },
        },
      })
      if (e.target.value === 'lead') {
        const oldLeadIndex = this.state.design.collaborators[e.target.dataset.status].findIndex(
          field => field.access == 'lead'
        )
        design = Update(design, {
          collaborators: {
            [e.target.dataset.status]: {
              [oldLeadIndex]: { [e.target.name]: { $set: 'c' } },
            },
          },
        })
      }
      this.setState({ design: design })
      if (
        this.state.company_id &&
        this.state.company_domain &&
        /\S/.test(e.target.value) &&
        validateEmail(e.target.value) &&
        !new RegExp(`.*@${this.state.company_domain}$`).test(e.target.value)
      ) {
        $('.company_collaborator_domain_error_text').removeClass('hidden').addClass('show')
      } else {
        $('.company_collaborator_domain_error_text').removeClass('show').addClass('hidden')
      }
    } else {
      const field = e.target.name
      design[field] = e.target.value
      return this.setState({ design: design })
    }
  }
  removeCollaboratorButton(status, id) {
    let newDesign
    newDesign = Update(this.state.design, {
      collaborators: {
        [status]: {
          $set: this.state.design.collaborators[status].filter(colla => colla.id !== id),
        },
      },
    })
    this.setState({ design: newDesign })
  }
  addCollaboratorButton() {
    let newDesign = Update(this.state.design, {
      collaborators: {
        adding: {
          $push: [
            {
              id: this.state.design.collaborators.adding.length + 1,
              email: '',
              access: 'c',
            },
          ],
        },
      },
    })
    return this.setState({ design: newDesign })
  }
  saveDesign(e) {
    e.preventDefault()
    const that = this

    if (this.elements.name) {
      if (!this.elements.name.value) {
        that.setState(({ errors }) => ({
          errors: { ...errors, nameRequired: true },
        }))
        return
      } else {
        that.setState(({ errors }) => ({
          errors: { ...errors, nameRequired: false },
        }))
      }
    }

    that.setState({ saving: true })

    // Log to GA for collaborators invite
    if (
      that.state.design.collaborators.adding.length >= 0 &&
      undefined != that.state.design.collaborators.adding[0] &&
      that.state.design.collaborators.adding[0].email
    ) {
      GoogleAnalyticsHelper.VentionGAEvent('inviting_collaborator', 'click', 'viral loop')
    }
    // updating a design
    if (!that.state.newAssembly) {
      DesignService.updateOneDesign(that.state.design, function (design) {
        that.setState({ design: design, saved: true, saving: false }, function () {
          that.callback && that.callback(design)
          $('#edit_assembly_card').modal('hide')
        })
      })

      // cloning a design bcoz there is a design id but it is not a new assembly.
    } else if (this.state.design.id) {
      let design = this.state.design
      DesignService.cloneOneDesign(design, function (newClonedDesign) {
        that.setState({ design: newClonedDesign, saved: true, saving: false }, function () {
          $('#edit_assembly_card').modal('hide')
          window.location.href = `/machine-builder/${that.state.design.id}`
        })
      })
      // if there is not any design id and it is a new assembly then it means creating a new design
    } else {
      let design = this.state.design
      DesignService.saveOneDesign(design, function (newDesign, err) {
        GoogleAnalyticsHelper.VentionGAEvent(
          'created_assembly',
          'click',
          'funnel',
          that.state.currentUser.id
        )
        if (err) {
          return that.setState({ saving: false })
        }
        if (!that.callback) {
          window.location.href = `/machine-builder/${newDesign.id}`
        } else {
          that.setState({ design: newDesign, saved: true, saving: false }, function () {
            $('#edit_assembly_card').modal('hide')
          })
        }
      })
    }
  }
  toggleShareLink(event) {
    const that = this
    const newValue = event.target.value
    if (newValue !== '') {
      DesignService.requestDesignSharingLink(this.state.design.id, newValue, res => {
        that.setState(
          {
            sharable_link: res.link,
            selected_sharable_link_option: newValue,
          },
          () => {
            that.sharable_link_input.current.focus()
            that.sharable_link_input.current.select()
            document.execCommand('copy')
          }
        )
      })
    } else {
      that.setState({ sharable_link: '', selected_sharable_link_option: '' })
    }
  }
  copyLink() {
    this.sharable_link_input.current.focus()
    this.sharable_link_input.current.select()
    document.execCommand('copy')
  }
  showDesignCards(index, productizedApplicationList) {
    return {
      querySettings: {
        ...this.state.querySettings,
        category: this.state.productizedApplications[index].parent.code,
        subCategory: this.state.productizedApplications[index].code,
        productizedApplications: productizedApplicationList
          ? productizedApplicationList
          : this.state.productizedApplications,
      },
      tab: 't',
    }
  }
  showEditProperties(index) {
    return {
      design: {
        ...this.state.design,
        design_category: {
          code: this.state.productizedApplications[index].code,
          id: this.state.productizedApplications[index].id,
          name: this.state.productizedApplications[index].name,
          parent: {
            id: this.state.productizedApplications[index].id,
            name: this.state.productizedApplications[index].name,
            code: this.state.productizedApplications[index].parent.code,
          },
          parent_id: this.state.productizedApplications[index].id,
        },
      },
      tab: 'p',
    }
  }

  isCloning() {
    return this.state.newAssembly && !!this.state.design.id
  }

  isCreatingNew() {
    return this.state.newAssembly && !this.state.design.id
  }

  getModalTitle() {
    if (!this.state.newAssembly && this.state.share) {
      return 'Add Collaborators'
    } else if (this.state.tab !== 'p') {
      return 'Create a new design'
    } else if (!this.state.newAssembly) {
      return 'Edit Design Properties'
    } else if (this.isCloning()) {
      return 'Save As'
    } else {
      return 'Design Properties'
    }
  }

  maskNumbersOnly(event) {
    return (
      (event.charCode != 8 && event.charCode == 0) || (event.charCode >= 48 && event.charCode <= 57)
    )
  }

  render() {
    let backButton,
      headerSubitle,
      leadCollaboratorField,
      collaboratorFields,
      confirmedCollaboratorFields,
      pendingCollaboratorFields,
      addingCollaboratorFields
    const that = this
    let content
    switch (this.state.tab) {
      case 'n':
        headerSubitle = <p>Start by selecting an application</p>
        content = (
          <div className='modal-body container-fluid'>
            <div className='assembly-card-body'>
              <div className='assembly-card design-card'>
                <div className='button-info'>
                  <i className='icon-add-design'></i>
                  <div>Blank design</div>
                </div>
                <div className='design-card-overlay'>
                  <button
                    className='button button-white-outline'
                    onClick={e => {
                      e.preventDefault()
                      this.setState({ tab: 'p' })
                    }}
                  >
                    Select
                  </button>
                </div>
              </div>
              {this.state.productizedApplications.map((productizedApplication, index) => {
                return (
                  <div key={productizedApplication.id} className='assembly-card design-card'>
                    <div className='assembly-card-img'>
                      <img
                        src={productizedApplication.image}
                        alt={productizedApplication.name}
                        loading='lazy'
                      ></img>
                    </div>
                    <div className='assembly-card-name design-card-name'>
                      {productizedApplication.name}
                    </div>
                    <div className='design-card-overlay'>
                      <button
                        className='button button-white-outline'
                        onClick={() => {
                          if (!this.state.productizedApplications[index].designs) {
                            DesignService.fetchDesigns(
                              {
                                ...this.state.querySettings,
                                category: productizedApplication.parent.code,
                                subCategory: productizedApplication.code,
                              },
                              (res, error) => {
                                if (res) {
                                  let productizedApplicationList =
                                    this.state.productizedApplications.map(
                                      (_productizedApplication, _index) => {
                                        if (_index == index) {
                                          _productizedApplication.designs = res.designs
                                        }
                                        return _productizedApplication
                                      }
                                    )

                                  if (res.designs.length > 0) {
                                    this.setState(
                                      this.showDesignCards(index, productizedApplicationList)
                                    )
                                  } else {
                                    this.setState(this.showEditProperties(index))
                                  }
                                } else {
                                  console.error(error)
                                }
                              }
                            )
                          } else if (this.state.productizedApplications[index].designs.length > 0) {
                            this.setState(this.showDesignCards(index))
                          } else {
                            this.setState(this.showEditProperties(index))
                          }
                        }}
                      >
                        Select
                      </button>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )
        break
      case 't':
        backButton = (
          <button type='button' className='back' onClick={() => this.setState({ tab: 'n' })}>
            &lt; Back
          </button>
        )
        headerSubitle = <p>Select a template</p>
        content = (
          <div className='modal-body container-fluid'>
            <div className='assembly-card-body'>
              {Object.values(this.state.productizedApplications).map(productizedApplication => {
                if (
                  productizedApplication.code === this.state.querySettings.subCategory &&
                  productizedApplication.parent.code === this.state.querySettings.category
                ) {
                  return productizedApplication.designs.map((designTemplate, index) => {
                    return (
                      <SimplifiedDesignCard
                        design={designTemplate}
                        key={index}
                        onSelect={() => {
                          this.cloneDesignTemplate(designTemplate)
                        }}
                      />
                    )
                  })
                }
              })}
            </div>
          </div>
        )
        break
      case 'p':
      case 'c':
        leadCollaboratorField = (
          <AddCollaboratorField
            access='lead'
            key={0}
            {...this.state.currentUser}
            name_or_email={this.state.currentUser.display_name}
            newAssembly={this.state.newAssembly}
            designId={this.state.design.id}
            removeCollaboratorButton={this.removeCollaboratorButton}
            status='confirmed'
            updateDesign={this.updateDesign}
          />
        )
        if (this.isCloning()) {
          collaboratorFields = this.state.design.collaborators.adding.map((colla, z) => (
            <AddCollaboratorField
              key={z + 1}
              {...colla}
              name_or_email={colla.email}
              newAssembly={this.state.newAssembly}
              designId={this.state.design.id}
              removeCollaboratorButton={this.removeCollaboratorButton}
              status='adding'
              updateDesign={this.updateDesign}
            />
          ))

          collaboratorFields = (
            <div>
              {leadCollaboratorField}
              {collaboratorFields}
            </div>
          )
        } else if (this.state.newAssembly) {
          collaboratorFields = this.state.design.collaborators.adding.map((colla, z) => (
            <AddCollaboratorField
              key={z + 1}
              {...colla}
              name_or_email={colla.email}
              newAssembly={this.state.newAssembly}
              designId={this.state.design.id}
              removeCollaboratorButton={this.removeCollaboratorButton}
              status='adding'
              updateDesign={this.updateDesign}
            />
          ))

          collaboratorFields = (
            <div>
              {leadCollaboratorField}
              {collaboratorFields}
            </div>
          )
        } else {
          leadCollaboratorField = this.state.design.collaborators.confirmed
            .filter(colla => colla.access == 'lead')
            .map((colla, z) => (
              <AddCollaboratorField
                key={z + 1}
                {...colla}
                name_or_email={colla.name_or_email}
                newAssembly={this.state.newAssembly}
                originalLeadDesigner={this.state.design.lead_designer}
                designId={this.state.design.id}
                removeCollaboratorButton={this.removeCollaboratorButton}
                status='confirmed'
                updateDesign={this.updateDesign}
              />
            ))
          confirmedCollaboratorFields = this.state.design.collaborators.confirmed
            .filter(colla => colla.access != 'lead')
            .map((colla, z) => {
              return (
                <AddCollaboratorField
                  key={z + 1}
                  {...colla}
                  name_or_email={colla.email}
                  newAssembly={that.state.newAssembly}
                  originalLeadDesigner={this.state.design.lead_designer}
                  designId={that.state.design.id}
                  removeCollaboratorButton={that.removeCollaboratorButton}
                  currentUser={that.state.currentUser}
                  status='confirmed'
                  updateDesign={that.updateDesign}
                  leadDesigner={that.state.currentUser.id == that.state.design.lead_designer_id}
                />
              )
            })

          pendingCollaboratorFields = this.state.design.collaborators.pending.map((colla, z) => (
            <AddCollaboratorField
              key={z + 1}
              {...colla}
              name_or_email={colla.email}
              newAssembly={this.state.newAssembly}
              designId={this.state.design.id}
              removeCollaboratorButton={this.removeCollaboratorButton}
              status='pending'
              updateDesign={this.updateDesign}
            />
          ))

          addingCollaboratorFields = this.state.design.collaborators.adding.map((colla, z) => (
            <AddCollaboratorField
              key={z + 1}
              {...colla}
              name_or_email={colla.email}
              newAssembly={this.state.newAssembly}
              designId={this.state.design.id}
              removeCollaboratorButton={this.removeCollaboratorButton}
              status='adding'
              updateDesign={this.updateDesign}
            />
          ))
          collaboratorFields = (
            <div>
              {leadCollaboratorField}
              {confirmedCollaboratorFields}
              {pendingCollaboratorFields}
              {addingCollaboratorFields}
            </div>
          )
        }
        content = (
          <div className='modal-body container-fluid edit-properties'>
            {!this.state.share && (
              <div>
                <div className='row'>
                  <div
                    className={classnames('col-sm-6 form-group text-left', {
                      'has-error': this.state.errors.nameRequired,
                    })}
                  >
                    <label htmlFor='name'>
                      <div>Design Name*</div>
                    </label>
                    <input
                      ref={this.setNameRef}
                      name='name'
                      value={this.state.design.name}
                      className='form-control'
                      onChange={this.updateDesign}
                    />
                    {this.state.errors.nameRequired && (
                      <div className='design_name_required_error_text'>
                        The design name is required.
                      </div>
                    )}
                  </div>

                  <div className='col-sm-6 form-group text-left'>
                    <label htmlFor='category'>Application Category</label>
                    <VentionSelect
                      key={this.state.design.design_category}
                      updateDesign={this.updateDesign}
                      design_category={this.state.design.design_category}
                      ref={this.setVentionSelectRef}
                    />
                    {this.isCreatingNew() && (
                      <button
                        className='button-browse-templates'
                        type='button'
                        onClick={() => this.setState({ tab: 'n' })}
                      >
                        Browse templates for this application
                      </button>
                    )}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-12 form-group text-left'>
                    <label htmlFor='description'>Description</label>
                    <textarea
                      name='description'
                      value={this.state.design.description ? this.state.design.description : ''}
                      onChange={this.updateDesign}
                      className='form-control'
                      rows={3}
                    />
                  </div>
                </div>
                {((this.state.newAssembly && this.isCloning()) || !this.state.newAssembly) && (
                  <div className='row'>
                    <div className='col-sm-12'>
                      <div className='form-group text-left'>
                        <label htmlFor='tags'>Tags</label>
                        <VentionTag update={this.updateDesign} tags={this.state.design.tags} />
                      </div>
                    </div>
                  </div>
                )}
                {this.state.ventionEmployee && !this.state.newAssembly && (
                  <div className='row'>
                    <div className='col-sm-6'>
                      <div className='form-group text-left'>
                        <label htmlFor='salesforce_opportunity_url'>
                          Salesforce Opportunity URL
                        </label>
                        <input
                          name='salesforce_opportunity_url'
                          value={this.state.design.salesforce_opportunity_url || ''}
                          className='form-control'
                          ref={this.setSalesforceURLRef}
                          onChange={this.updateDesign}
                        />
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <div className='form-group text-left'>
                        <label htmlFor='predicted_purchase_quantity'>
                          Predicted Purchase Quantity
                        </label>
                        <input
                          name='predicted_purchase_quantity'
                          type='number'
                          value={this.state.design.predicted_purchase_quantity || ''}
                          ref={this.setSalesforceQtyRef}
                          className='form-control'
                          onChange={this.updateDesign}
                          onKeyPress={this.maskNumbersOnly}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {this.state.share && !this.state.newAssembly && (
              <div
                style={{
                  marginBottom: '20px',
                  paddingBottom: '40px',
                  borderBottom: '1px solid #DBDBDB',
                }}
              >
                <div className='no-padding bold' style={{ marginBottom: '20px' }}>
                  <i className='fal fa-link' aria-hidden='true' style={{ fontSize: '1.3em' }}></i>
                  &nbsp;&nbsp;
                  <span>Share this project via private share link:</span>
                </div>
                <div className='row'>
                  <div className='col-sm-4'>
                    <div className='collaborator-field form-group select-wrapper'>
                      <select
                        name='design_share_link'
                        className='form-control'
                        onChange={this.toggleShareLink}
                        value={this.state.selected_sharable_link_option}
                      >
                        <option value=''>Select link type</option>
                        <option value='true'>View only (with price)</option>
                        <option value='false'>View only (without price)</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-sm-5'>
                    <div className='collaborator-field form-group flex-centering'>
                      <input
                        className='form-control'
                        defaultValue={this.state.sharable_link}
                        ref={this.sharable_link_input}
                      />
                    </div>
                  </div>
                  <div className='col-sm-3'>
                    <div className='collaborator-field form-group flex-centering'>
                      <button
                        className='form-control button button-outline'
                        onClick={this.copyLink}
                      >
                        Copy
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div>
              <div className='bold' style={{ marginBottom: '5px' }}>
                <i className='fa fa-user-plus' aria-hidden='true'></i>
                &nbsp;&nbsp;Add collaborators:
              </div>
              <div className='row'>
                <div className='col-sm-6'>
                  <label className='vention_blue'>User</label>
                </div>
                <div className='col-sm-3'>
                  <label className='vention_blue'>
                    Role&nbsp;
                    <i
                      className='far fa-info-circle'
                      aria-hidden='true'
                      data-toggle='popover'
                      data-trigger='hover'
                      data-html={true}
                      data-content='<b>Lead</b> - can view, edit and configure the design, and can change the role of collaborators<br/><b>Collaborator</b> – can view, edit and configure the design<br/><b>Design Viewer</b> –  cannot modify the design or its properties'
                    ></i>
                  </label>
                </div>
                <div className='col-sm-3'>
                  <label className='vention_blue' style={{ paddingLeft: '12px' }}>
                    Status&nbsp;
                    <i
                      className='far fa-info-circle'
                      aria-hidden='true'
                      data-toggle='popover'
                      data-trigger='hover'
                      data-html={true}
                      data-content='<b>Confirmed</b> – has a vention.io account and has been added to your design<br/><b>Pending</b> – has not yet activated a vention.io account'
                    ></i>
                  </label>
                </div>
              </div>
              {collaboratorFields}
              <div className='form-group'>
                <span className='vention-green-link' onClick={this.addCollaboratorButton}>
                  Add more collaborators
                </span>
              </div>
              <div className='company_collaborator_domain_error_text error_text hidden'>
                Are you sure you want to add collaborators from outside of your company?
              </div>
              <div className='company_collaborator_duplicate_error_text error_text hidden'>
                You have duplicated emails.
              </div>
            </div>
            {!this.state.share &&
              ((this.state.newAssembly && this.isCloning()) || !this.state.newAssembly) && (
                <div className='center-self'>
                  <React.Fragment>
                    <div className='row'>
                      {((this.state.newAssembly && this.isCloning()) ||
                        !this.state.newAssembly) && (
                        <div className='col-sm-6 form-group text-left select-wrapper'>
                          <label htmlFor='privacy'>
                            Privacy&nbsp;
                            <i
                              className='far fa-info-circle'
                              id='abc'
                              aria-hidden='true'
                              data-toggle='popover'
                              data-trigger='hover'
                              data-html={true}
                              data-content={`<div style="opacity: 0.5;">Get discovered and boost your reputation score by setting your design to public</div><br/>
                                  <b>Private</b> - Your design is private to you and your collaborators.<br/>
                                  ${
                                    this.state.company_id
                                      ? '<b>My team</b> - Your design is accessible as Read-only to members of your company. Company members are allowed to create copies.<br>'
                                      : ''
                                  }
                                  <b>Public</b> - Your design is accessible as Read-only to all members of the Vention community. Members are allowed to create copies.<br/>`}
                            ></i>
                          </label>
                          <select
                            name='visibility_level'
                            className='form-control'
                            onChange={this.updateDesign}
                            value={this.state.design.visibility_level}
                          >
                            <option value='p'>Private</option>
                            {this.state.company_id ? <option value='c'>My team</option> : null}
                            {this.state.originalDesign?.visibility_level == 'a' && !this.isCloning() ? (
                              <option value='a'>Public</option>
                            ) : (
                              <option value='a' disabled>
                                Public (Publish design to make public)
                              </option>
                            )}
                          </select>
                        </div>
                      )}
                      {!this.state.newAssembly && (
                        <div className={'col-sm-6 form-group text-left select-wrapper'}>
                          <label htmlFor='lifecycle'>
                            Lifecycle&nbsp;
                            <i
                              className='far fa-info-circle'
                              id='abc'
                              aria-hidden='true'
                              data-toggle='popover'
                              data-trigger='hover'
                              data-html={true}
                              data-content={
                                'Select a lifecycle status to organize your projects and keep your team members up to date on project progression<br/>'
                              }
                            ></i>
                          </label>
                          <select
                            name='lifecycle'
                            className='form-control'
                            onChange={this.updateDesign}
                            value={this.isCloning() ? 'progress' : this.state.design.lifecycle}
                          >
                            <option value='progress'>In Progress</option>
                            <option value='review'>Under review</option>
                            <option value='ready_to_order'>Ready to order</option>
                            <option value='comissioned'>Commissioned</option>
                            <option value='archived'>Archived</option>
                          </select>
                        </div>
                      )}
                      {!this.state.newAssembly &&
                        (this.state.originalDesign?.visibility_level == 'a' && !this.isCloning() ? null : (this.state.currentUser.id === this.state.design.lead_designer_id) && (
                          <div className='col-sm-12'>
                            <span
                              className='vention-green-link'
                              onClick={() => {
                                // TO DO: Save changes to design properties before displaying the publish dialog in the CAD
                                $('#edit_assembly_card').modal('hide')
                                !this.openPublishDesignDialog
                                  ? (window.location.href = `/machine-builder/${this.state.design.id}?publish=true`)
                                  : this.openPublishDesignDialog()
                              }}
                            >
                              Publish your design
                            </span>
                          </div>
                        ))}
                    </div>
                  </React.Fragment>
                </div>
              )}
          </div>
        )
        break
    }

    return (
      <div
        className='modal fade'
        id='edit_assembly_card'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='myModalLabel'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              {backButton}
              <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true' className='icon-close'></span>
              </button>
              <h2 className='modal-title text-center'>{this.getModalTitle()}</h2>
              {headerSubitle}
            </div>
            {content}
            {(this.state.tab == 'p' || this.state.tab == 'c') && (
              <div className='modal-footer'>
                <svg xmlns='http://www.w3.org/2000/svg'>
                  <polygon points='0,0 10,10 20,0' />
                </svg>
                <div>
                  {!this.state.newAssembly && !this.state.share && (
                    <a
                      className='update-thumbnail'
                      onClick={() => {
                        // TO DO: Save changes to design properties before displaying the thumbnail capture dialog in the CAD
                        $('#edit_assembly_card').modal('hide')
                        !this.openUpdateThumbnailsDialog
                          ? (window.location.href = `/machine-builder/${this.state.design.id}?update_thumbnail=true`)
                          : this.openUpdateThumbnailsDialog()
                      }}
                    >
                      <span className='icon-camera'></span>
                      Update Thumbnail
                    </a>
                  )}
                  <Button
                    className='button button-md button-success'
                    onClick={this.saveDesign}
                    ref={this.setSaveButtonRef}
                    disabled={this.cantSaveDesign()}
                    isLoading={this.state.saving}
                  >
                    {!this.state.newAssembly || this.state.share ? 'Save' : 'Start designing'}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default AssemblyCard
