import { useState, useEffect } from 'react'
import { Part, PartWithQuantity } from '../api/Parts/Types'
import AdditionalPart from '../models/checkout/AdditionalPart'
import { currencyCb } from '../components/common/Currency'

interface UseAdditionalParts {
  readonly additionalParts: ReadonlyArray<AdditionalPart>
  readonly additionalPartTotal: number
  readonly recomputePrice: () => void
  readonly buildAdditionalParts: (parts: ReadonlyArray<PartWithQuantity>) => void
}

export function useAdditionalParts(parts: ReadonlyArray<Part>): UseAdditionalParts {
  const [additionalParts, setAdditionalParts] = useState<ReadonlyArray<AdditionalPart>>([])
  const [additionalPartTotal, setAdditionalPartTotal] = useState(0)

  const buildAdditionalParts = (parts: ReadonlyArray<PartWithQuantity>): void => {
    let totalCost = 0
    const builtAdditionalParts = parts.map(part => {
      const additionalPart = new AdditionalPart(
        part.id,
        part.part_number,
        part.name,
        part.unit_price,
        part.currency_symbol,
        part.currency_code,
        part.category,
        part.thumbnail_image_path,
        part.quantity,
        part.unit_of_measurement,
        part.unit_of_measurement_price_label
      )
      totalCost = currencyCb(additionalPart.totalPrice).add(totalCost).value
      return additionalPart
    })

    setAdditionalParts(builtAdditionalParts)
    setAdditionalPartTotal(totalCost)
  }

  useEffect(() => {
    if (parts.length) {
      buildAdditionalParts(parts)
    }
  }, [])

  const recomputePrice = (): void => {
    let totalCost = 0
    for (let i = 0; i < additionalParts.length; i++) {
      totalCost = currencyCb(additionalParts[i].unitPrice)
        .multiply(additionalParts[i].quantity)
        .add(totalCost).value
    }
    setAdditionalPartTotal(totalCost)
  }

  return {
    additionalParts,
    additionalPartTotal,
    recomputePrice,
    buildAdditionalParts,
  }
}
